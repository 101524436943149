import React, { useEffect, useState } from "react";
import { useCreateNewsMutation } from "../../../redux/api/newsApi";
import { toast } from "react-hot-toast";
import { IoNewspaperOutline } from "react-icons/io5";

const CreateNews = () => {
  const [data, setData] = useState({});
  const [previewImage, setPreviewImage] = useState("");

  const [create, { isSuccess, error }] = useCreateNewsMutation();

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const onChangeImage = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setData({ ...data, image: reader.result });
        setPreviewImage(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const submitHandler = () => {
    create(data);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Haber Oluşturuldu.");
    }
    if (error) toast.error(error?.data?.message);
  }, [isSuccess, error]);
  return (
    <div className="row">
      <h1>Haber Oluştur</h1>
      <div className="col">
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Haber Başlığı
          </label>
          <input
            type="text"
            className="form-control"
            name="title"
            placeholder="Haber başlığı"
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subtitle" className="form-label">
            Haber Altbaşlığı
          </label>
          <input
            type="text"
            className="form-control"
            name="subtitle"
            placeholder="Haber altbaşlığı"
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="content" className="form-label">
            Haber İçeriği
          </label>
          <textarea
            type="text"
            className="form-control"
            name="content"
            placeholder="Haber içeriği"
            onChange={onChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="form-label">
            Haber Kapak Resmi
          </label>
          <input
            type="file"
            className="form-control"
            name="image"
            onChange={onChangeImage}
          />
        </div>
        {previewImage !== "" && (
          <img
            src={previewImage}
            alt=""
            className="rounded-2"
            style={{ width: "120px", height: "120px" }}
          />
        )}
        <div className="mb-3 d-flex justify-content-end">
          <button className="btn btn-primary" onClick={() => submitHandler()}>
            Oluştur
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateNews;
