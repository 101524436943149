import React, { useEffect, useState } from "react";
import {
  useGetEventDetailsQuery,
  useUpdateEventMutation,
} from "../../../redux/api/eventApi";
import { FaRegTrashAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { Editor } from "primereact/editor";

const EditEvent = () => {
  const { slug } = useParams();
  const [data, setData] = useState({});
  const [images, setImages] = useState({});
  const [previewImage, setPreviewImage] = useState([]);
  
  const [update, { isSuccess, error, isLoading }] = useUpdateEventMutation();
  const { data: event } = useGetEventDetailsQuery(slug);
  console.log(event);

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const onChangeImage = async (e) => {
    const files = Array.from(e.target.files);

    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve({
            url: reader.result,
            isBase64: true,
            _id: Math.floor(10000000 + Math.random() * 90000000),
          });
        };
      });
    });

    await Promise.all(promises).then((base64Images) => {
      setPreviewImage((prevImages) => [...prevImages, ...base64Images]);
    });
  };
  const removeImg = (_id) => {
    const filteredImages = previewImage.filter((image) => image._id !== _id);

    setPreviewImage(filteredImages);
    setData({ ...data, ...filteredImages });
  };

  console.log(previewImage);

  const submitHandler = () => {
    console.log(data);
    const {title, content} = data
    update({title, content, images:previewImage, id: event?.event?._id});
  };

  useEffect(() => {
    if (event) {
      setData({
        title: event.event.title,
        content: event.event.content,
        images: event.event.images,
      });
      setPreviewImage(event.event.images);
    }
    if (isSuccess) {
      toast.success("Etkinlik Oluşturuldu.");
    }
    if (error) toast.error(error?.data?.message);
  }, [isSuccess, error, event]);

  return (
    <div className="row border rounded ">
      <h1>Etkinlik Güncelle</h1>
      <div className="col mt-3">
        <div className="mb-3">
          <label
            htmlFor="title"
            className="form-label"
            style={{ fontWeight: "500" }}
          >
            Etkinlik Başlığı:
          </label>
          <input
            type="text"
            className="form-control"
            name="title"
            value={data.title}
            placeholder="Haber başlığı"
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="content"
            className="form-label"
            style={{ fontWeight: "500" }}
          >
            Etkinlik İçeriği:
          </label>
          <Editor
            name="content"
            onChange={onChange}
            onTextChange={(e) => setData({ ...data, content: e.htmlValue })}
            style={{ height: "320px" }}
            value={data?.content}
          />
        </div>
        <div className="mb-3">
          <label
            htmlFor="images"
            className="form-label"
            style={{ fontWeight: "500" }}
          >
            Etkinlik Resmi:
          </label>
          <input
            type="file"
            className="form-control"
            name="images"
            multiple
            onChange={onChangeImage}
          />
        </div>
        {previewImage !== "" && (
          <div className="d-flex gap-2">
            {previewImage?.map((img, index) => (
              <div
                className="border rounded-2"
                style={{ position: "relative" }}
                onClick={() => removeImg(img._id)}
              >
                <img
                  src={img.url}
                  alt=""
                  className="rounded-2"
                  style={{ width: "120px", height: "120px", cursor: "pointer" }}
                />
                <div className="preview-img rounded-2 ">
                  <FaRegTrashAlt color="white" size={24} />
                </div>
                <div
                  className={`${
                    index === 0 ? "active-preview-img rounded-bottom" : "d-none"
                  } `}
                >
                  KAPAK RESMİ
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="mb-3 d-flex justify-content-end">
          <button
            className="btn btn-primary"
            disabled={isLoading}
            onClick={() => submitHandler()}
          >
            {isLoading ? "Güncelleniyor..." : "Güncelle"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
