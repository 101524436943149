import React from "react";

const RightSidebar = () => {
  return (
    <div id="sag_menu_holder">
      <div id="anasayfa-social-area">
        <a target="_blank" href="https://www.facebook.com/tuglar/">
          <div>
            <img src="/images/yeni_tasarim/Icon_facebook.png" />
          </div>
        </a>

        <a target="_blank" href="https://x.com/turanocaklarii">
          <div>
            <img src="/images/yeni_tasarim/Icon_twitter.png" />
          </div>
        </a>

        <a
          id="anasayfa-social-link-instagram"
          target="_blank"
          href="https://www.instagram.com/turanocaklarii/"
        >
          <div>
            <img src="/images/yeni_tasarim/Icon_instagram.png" />
          </div>
        </a>

        <a
          target="_blank"
          id="anasayfa-social-link-youtube"
          href="https://www.youtube.com/"
        >
          <div>
            <img src="/images/yeni_tasarim/Icon_youtube.png" />
          </div>
        </a>
      </div>

      <div id="yeni_tasarim_sag_menu">
        <ul>
          <li>
            <a href="/yakinda">
              <div className="sag-menu-border-holder">
                <div className="image-holder">
                  <img src="/images/yeni_tasarim/icon_parlamentolar_arasi_iliskiler.png" />
                </div>
                HALKLA İLİŞKİLER
              </div>
            </a>
          </li>
          <li>
            <a href="/haberler">
              <div className="sag-menu-border-holder">
                <div className="image-holder">
                  <img src="/images/yeni_tasarim/icon_kutuphane.png" />
                </div>
                HABERLER VE BÜLTEN
              </div>
            </a>
          </li>
          <li>
            <a href="/tuzuk/tuzuk">
              <div className="sag-menu-border-holder">
                <div className="image-holder">
                  <img src="/images/yeni_tasarim/icon_anayasa.png" />
                </div>
                TÜZÜK
              </div>
            </a>
          </li>
          <li>
            <a href="/yakinda">
              <div className="sag-menu-border-holder">
                <div className="image-holder">
                  <img src="/images/yeni_tasarim/icon_kanun_ve_kararlar.png" />
                </div>
                KARARLAR BİLGİ SİSTEMİ
              </div>
            </a>
          </li>
          <li>
            <a href="/baskan/iletisim">
              <div className="sag-menu-border-holder">
                <div className="image-holder">
                  <img src="/images/yeni_tasarim/icon_edilekce.png" />
                </div>
                İLETİŞİM
              </div>
            </a>
          </li>
          <li>
            <a href="/tuzuk/hakkimizda">
              <div className="sag-menu-border-holder">
                <div className="image-holder">
                  <img src="/images/yeni_tasarim/icon_bilgi_edinme.png" />
                </div>
                HAKKIMIZDA
              </div>
            </a>
          </li>
        </ul>
      </div>

      <div id="sag-menu-duyurular-alani" style={{ marginTop: "15px" }}>
        <div id="sag-menu-duyurular-alani-holder">
          <div id="sag-menu-duyurular-alani-baslik">DUYURULAR</div>
        </div>
        <div id="sag-menu-duyurular-icerigi-holder">
          <ul>
            <li>
              <a href="/baskan/mesajlar">
                <span className="duyuru-baslik">Genel Duyurular</span>
              </a>
            </li>

            <li>
              <a href="/yakinda">
                <span className="duyuru-baslik">Eğitim/Seminer Duyuruları</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RightSidebar;
