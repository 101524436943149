import React, { useEffect } from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";
import { useGetEventsQuery } from "../../redux/api/eventApi";
import Loader from "../../layouts/Loader";

const Vakif = () => {
  const { data: events, isLoading } = useGetEventsQuery({ category: "vakif" });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PageLayouts>
      <MetaData title={"Bağış"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Turan Ocakları Eğitim ve Kültür Vakfı
        </div>
        <div className="baskanlik-header">Vakıf Etkinlikleri</div>
      </div>
      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <div className="baskanlik-anasayfa-alt-haber">
            <div className="row">
              {events.events.map((event) => (
                <div className="col-md-6" style={{ marginBottom: "20px" }}>
                  <a href={`/etkinlikler/vakif/${event?.slug}`}>
                    <div
                      className="arh ImageLazyLoader"
                      data-resim-yolu=""
                      style={{
                        backgroundImage: `url('${event?.images[0]?.url}')`,
                      }}
                    ></div>

                    <div className="art">{event?.subtitle}</div>
                    <div className="arb">{event?.title}</div>
                    <div
                      className="ard line-3"
                      dangerouslySetInnerHTML={{ __html: event?.content }}
                    ></div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Vakif;
