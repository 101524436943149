import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";

const Ozgecmis = () => {
  return (
    <PageLayouts>
      <MetaData title={"Özgeçmiş"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Abdulkadir KAPLAN
        </div>
        <div className="baskanlik-header">ÖZGEÇMİŞ</div>
      </div>

      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="baskanlik-content">
          <div className="baskanlik-baskan-ozgecmis">
            <div style={{ float: "left", width: "30%" }}>
              <img
                style={{
                  width: "100%",
                  paddingRight: "5%",
                  maxHeight: "250px",
                  objectFit: "cover",
                }}
                src="/img/uyeler/abdulkadi-parlak-2.jpeg"
              />
            </div>
            <div style={{ textAlign: "justify" }}>
              Abdulkadir Kaplan Vatan ve Millet Aşkıyla Yoğrulmuş Bir Hayat
              Hikâyesi
              <br /> <br />
              1993 yılında Kayseri’nin Kocasinan ilçesinde dünyaya gelen
              Abdulkadir Kaplan, daha çocuk yaşlarda Türk milletine ve
              değerlerine olan bağlılığıyla dikkat çekmiştir. Ailesinin gelenek
              ve değerlerine sıkı sıkıya bağlı bir ortamda büyüyen Kaplan,
              gençlik yıllarından itibaren ülkesi için büyük hayaller kurmuş ve
              bu hayaller doğrultusunda adım atmaya başlamıştır. İlk ve orta
              öğrenimini İstanbul’un Bağcılar ilçesinde tamamlayan Kaplan,
              çevresinde sorumluluk bilinci ve liderlik vasfıyla tanınmıştır.
              <br /> <br />
              Eğitim hayatına Bakırköy Anadolu İmam Hatip Lisesi’nde devam eden
              Kaplan, bu dönemde yalnızca akademik başarılarıyla değil, aynı
              zamanda sosyal sorumluluk projelerine olan ilgisi ve liderlik
              yetenekleriyle de öne çıkmıştır. Henüz lise yıllarında cemiyet
              çalışmalarına aktif katılım göstererek, toplumun genç bireylerini
              bilinçlendirme adına çeşitli projelere öncülük etmiştir.
              <br /> <br />
              Siyasetteki İlk Adımlar ve Liderlik Dönemi
              <br /> <br />
              Kaplan, genç yaşlarda Türkiye'nin siyasi hayatına adım atmıştır.
              Bağcılar Güneşli Ülkü Ocakları’nda başladığı görev yolculuğunu,
              Saadet Partisi Bakırköy Gençlik Kolları Başkanlığı ile
              sürdürmüştür. Bu süreçte, gençlik hareketlerinde elde ettiği
              deneyimlerle, Türk siyasetinin genç kadrolarına örnek teşkil
              etmiştir. 2018 yılında, kültür ve eğitim odaklı bir vizyonla
              Nizam-ı Çeri Ocakları Eğitim ve Kültür Derneği’ni kurarak, Türk
              gençliğinin milli ve manevi değerlerle donatılmasına yönelik
              önemli çalışmalar yapmıştır.
              <br /> <br />
              2019 yılına kadar Bakırköy Alperen Ocakları İlçe Başkanlığı ve
              Bakırköy Büyük Birlik Partisi İlçe Başkan Yardımcılığı
              görevlerinde bulunan Kaplan, bu dönemde siyasi arenada hem
              gençliğin sesi hem de çözüm üreten bir lider olarak tanınmıştır.
              <br /> <br />
              Uluslararası Arenada Türk Bayrağını Dalgalandırmak
              <br /> <br />
              Sadece Türkiye sınırları içinde değil, uluslararası platformlarda
              da etkin bir şekilde çalışmalarını sürdüren Abdulkadir Kaplan,
              Ortadoğu’nun birçok ülkesinde Türkiye’nin çıkarlarına hizmet eden
              önemli faaliyetlerde bulunmuştur. 2021-2022 yılları arasında Milli
              Beka Hareketi Gençlik Kolları Genel Başkanlığı ve MKYK Üyeliği
              görevlerinde gençlik politikalarının geliştirilmesinde öncü bir
              rol üstlenmiştir.
              <br /> <br />
              Kaplan’ın diplomasi alanındaki vizyonu, 2022-2023 yılları arasında
              üstlendiği İçkerya Çeçen Cumhuriyeti Türkiye Fahri Konsolosluğu
              göreviyle daha da pekişmiştir. Bu görev süresince, Türk dünyasının
              sorunlarına duyarlılık göstererek iki ülke arasındaki bağların
              güçlenmesine katkıda bulunmuştur.
              <br /> <br />
              Turan Ocakları’nın İnşası ve Gelişimi
              <br /> <br />
              2022 yılında Turan Ocakları’nın Avrupa, Asya, Ortadoğu ve
              Balkanlar’da güçlenmesi için yürüttüğü teşkilatlanma çalışmaları,
              Abdulkadir Kaplan’ın liderlik yeteneklerinin bir kanıtıdır.
              Türkiye’nin dört bir yanında açılan teşkilatlar sayesinde binlerce
              kişiye ulaşılmış, Türk gençliği için ilham verici bir yapı
              oluşturulmuştur. Bu süreçte Kaplan, yalnızca bir lider değil, aynı
              zamanda Türk birliğinin bir sembolü olmuştur.
              <br /> <br />
              Eğitim, Uzmanlık ve Kültürel Çalışmalar
              <br /> <br />
              Kaplan, eğitim hayatında yalnızca sosyal bilimlerde değil, teknik
              alanlarda da kendini geliştirmiştir. Bilimsel Denizcilik Yüksek
              Okulu Yat Kaptanlığı Bölümü’nü başarıyla tamamlayan Kaplan,
              denizcilik sektöründeki yetkinliklerini ülkesinin hizmetine
              sunmuştur. İngilizceye olan hâkimiyeti, uluslararası
              platformlardaki etkinliğini artırmış, dünya genelinde temsil
              ettiği değerlerin daha güçlü bir şekilde aktarılmasını
              sağlamıştır.
              <br /> <br />
              Adanmışlık ve Gelecek Vizyonu
              <br /> <br />
              Abdulkadir Kaplan, Türk milletine olan adanmışlığıyla,
              karşılaştığı tüm zorlukları birer fırsata dönüştürerek
              çalışmalarına devam etmektedir. Gençlerin eğitimi, kültürel
              gelişimi ve milli bir şuur kazanması için var gücüyle çalışan
              Kaplan, vatanını ve milletini her şeyin üzerinde tutan bir lider
              olarak Türkiye’nin geleceğine ışık tutmaktadır.
              <br /> <br />
              Bugün, yalnızca Türkiye’nin değil, tüm Türk dünyasının umut
              bağladığı bir isim haline gelen Kaplan, azim, cesaret ve inançla
              çalışmalarını sürdürerek Türk milletine hizmet etmeye devam
              etmektedir.
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default Ozgecmis;
