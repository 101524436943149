import React from "react";
import RightSidebar from "../../layouts/RightSidebar";

const Kurban = () => {
  return (
    <div class="page-content">
      <div class="container  g-0">
        <div class="row g-0">
          <div class="col-lg-8 col-md-12">
            <div id="content-dinamik-title">
              <div id="content-title-type">
                <a
                  style={{ color: "#c4b084" }}
                  href="/haberler"
                >
                  HABERLER
                </a>
              </div>
              <div id="content-title-hepsi"></div>
            </div>

            <div id="detay-content" style={{ width: "100%" }}>
              <div class="blog-media">
                <img
                  class="HaberResimYukleme img-fluid"
                  data-resim-yolu=""
                  loading="lazy"
                  style={{ width: "100%" }}
                  src="/img/turan-ocaklari-basin-1-1_4.png"
                />
              </div>
              <div class="blog-content">
                <div id="text-description">
                  <div class="bright-text">
                    <div id="haber-detay-baslik">
                      <div
                        id="haber-detay-saat"
                        style={{display:"flex",justifyContent:"space-between"}}
                      >
                        <div
                          id="haber-detay-saat-dakika"
                          style={{display:"inline-block"}}
                        >
                          {" "}
                          17 Ocak 2024
                        </div>

                        <div class="mobile-social-media">
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://www.facebook.com/tuglar/"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_facebook.png" />
                          </a>
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://www.instagram.com/turanocaklarii/"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_instagram.png" />
                          </a>
                          <a
                            class="a-facebook"
                            target="_blank"
                            href="https://x.com/turanocaklarii"
                          >
                            <img src="/images/yeni_tasarim/renk/Icon_twitter.png" />
                          </a>
                        </div>
                      </div>
                      ÇAĞIN MANEVİ HASTALIKLARINA TEK ÇARE; Turan Ocaklarında!
                    </div>
                    <div id="haber-detay-golge">
                      <div id="haber-detay-ozet">
                        Manevi güçle çağın hastalıklarına meydan okuyoruz, Turan
                        Ocakları'nda birleşin!
                      </div>
                    </div>

                    <div id="haber-detay-aciklama">
                      <p>
                        Yaşadığımız çağ, sadece maddi değil, manevi olarak da
                        birçok zorluğa tanıklık ediyor. Toplumsal birliğimizi
                        tehdit eden hastalıklar, adeta ruhumuzu sarmalıyor. Bu
                        manevi yozlaşma, dışarıdan bakıldığında belki de en
                        fazla "Turan Ocakları" gibi milli, manevi ve eğitim
                        odaklı oluşumların önemini ortaya koyuyor.
                      </p>
                      <p>
                        Turan Ocakları, sadece bir gençlik oluşumu değil, aynı
                        zamanda manevi bir muhafız, bir değerler ve düşünce
                        okulu olma misyonunu taşıyor. Bu oluşum, manevi
                        hastalıkların, dedikodudan tembelliğe, sorumsuzluktan
                        siyasi hesaplara, hizipleşmeden aile uyumsuzluğuna kadar
                        uzanan birçok olumsuzluğun dışında bir konumda kendine
                        yer buluyor.
                      </p>
                      <p>
                        Turan Ocakları, bu manevi hastalıklara karşı bir
                        panzehir gibidir. Burada yer alan gençler, kendi öz
                        değerlerini korumanın yanı sıra ülke ve milletleri için
                        çıkış yolları arayarak kendilerini yetiştirmekte,
                        toplumsal birlik ve beraberliği sağlama adına öncülük
                        etmektedirler. Hem kişisel gelişimlerine odaklanmakta,
                        hem de milli değerlerin vakarını ve davanın şahsiyetini
                        muhafaza etmektedirler.
                      </p>
                      <p>
                        Turan Ocakları, manevi olarak güçlü bir toplumun
                        inşasında adeta birer yapı taşı gibi hareket ediyor. Bu
                        oluşum, gençlerin kişisel ve milli bilinçlenmesini
                        sağlayarak, dedikodu, tembellik, siyasi hesaplar ve
                        benzeri manevi hastalıklara karşı bir kalkan
                        oluşturuyor. Her bir genç kardeşimiz, bu oluşum
                        içerisinde kendini bulurken, aynı zamanda ülke ve
                        milletine hizmet etmenin gerekliliğini de
                        özümsemektedir.
                      </p>
                      <p>
                        Turan Ocakları, manevi hastalıkların pençesinden
                        kurtulan gençlerin inkişafına sahne olmaktadır. Bu
                        oluşum, manevi değerlerle beslenen gençlerin, topluma
                        yararlı fertler olarak yetişmesine olanak sağlamaktadır.
                      </p>
                      <p>
                        Toplumun manevi yönünü güçlendiren, gençlerin milli ve
                        manevi değerlerle donanmasını sağlayan bu gibi
                        oluşumlar, geleceğe umutla bakmamızı sağlıyor. Çünkü
                        onlar, çağın manevi hastalıklarına karşı en etkili ilaç
                        olma yolunda emin adımlarla ilerliyorlar. İşte bu
                        yüzden, Turan Ocakları, geleceğimizin güvencesi ve
                        manevi tedavi merkezi olma niteliğini taşıyor.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-12">
            <RightSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kurban;
