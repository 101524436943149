import React, { useEffect, useRef, useState } from "react";
import MetaData from "../layouts/MetaData";
import { useGetNewsQuery } from "../redux/api/newsApi";
import Loader from "../layouts/Loader";
import { useGetEventsQuery } from "../redux/api/eventApi";

const Home = () => {
  const [active, setActive] = useState("");
  const [newsList, setNewsList] = useState([]);

  const { data: news, isLoading, error } = useGetNewsQuery();
  const {data: events} = useGetEventsQuery();
  const haberlLinkleri = [
    {
      text: "VAKIF BAŞKANI",
      url: "haberAlaniMeclisBaskani",
    },
    {
      text: "HALK",
      url: "haberAlaniYasama",
    },
    {
      text: "TURAN OCAKLARI",
      url: "haberAlaniKomisyon",
    },
    {
      text: "ETKİNLİKLER",
      url: "haberAlaniMilletvekilleri",
    },
  ];

  const carouselNumberList = () => {
    for (let i = 0; i < news?.news?.length; i++) {}
  };

  const [activeHaberTuru, setActiveHaberTuru] = useState(
    "haberAlaniMeclisBaskani"
  );

  const AnasayfaHaberGetir = (haberTuru) => {
    setActiveHaberTuru(haberTuru);
  };

  useEffect(() => {
    if (news?.news) {
      setNewsList(Array?.from(news?.news)?.reverse()?.slice(1, 10));
    }
  }, [news]);

  return (
    <div>
      <MetaData title={"Anasayfa"} />
      <div id="anasayfa-banner-area">
        <div className="container">
          <div className="row g-0">
            <div className="col-lg-8 col-md-12">
              {isLoading ? (
                <Loader />
              ) : (
                <div
                  id="carouselAnasayfaSlider"
                  data-bs-ride="carousel"
                  className="carousel slide"
                >
                  <div id="carousel-indicators-holder">
                    <div id="carousel-indicators-holder-left">
                      <ol className="carousel-indicators">
                        <li
                          data-bs-target="#carouselAnasayfaSlider"
                          className="active"
                          aria-current="true"
                          data-bs-slide-to="0"
                        >
                          1
                        </li>
                        {news?.news
                          ?.slice(1, 10)
                          .reverse()
                          ?.map((item, index) => (
                            <li
                              data-bs-target="#carouselAnasayfaSlider"
                              className=""
                              aria-current=""
                              data-bs-slide-to={index + 1}
                            >
                              {index + 2}
                            </li>
                          ))}
                      </ol>
                    </div>
                    <div id="carousel-indicators-holder-right">
                      <a href="/haberler">HEPSİ </a>
                      <img
                        style={{ width: "8%", marginBottom: "5px" }}
                        src="/images/yeni_tasarim/WhiteRightArrowIcon.png"
                      />
                    </div>
                  </div>

                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <a href={`/haber/${news?.news?.slice(-1)[0]?.slug}`}>
                        <img src={news?.news?.slice(-1)[0]?.image?.url} />

                        <div className="carousel-caption d-md-block">
                          <span className="carousel-baslik">
                            {news?.news?.slice(-1)[0]?.title}
                          </span>
                        </div>
                      </a>
                    </div>

                    {newsList?.map((item) => (
                      <div className="carousel-item">
                        <a href={`/haber/${item?.slug}`}>
                          <img src={item?.image?.url} />

                          <div className="carousel-caption d-md-block">
                            <span className="carousel-baslik">
                              {item?.title}
                            </span>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselAnasayfaSlider"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselAnasayfaSlider"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              )}
            </div>

            <div className="col-lg-4 col-md-12">
              <div id="anasayfa-social-area">
                <a target="_blank" href="https://www.facebook.com/tuglar/">
                  <div>
                    <img src="/images/yeni_tasarim/Icon_facebook.png" />
                  </div>
                </a>

                <a target="_blank" href="https://twitter.com/turanocaklarii">
                  <div>
                    <img src="/images/yeni_tasarim/Icon_twitter.png" />
                  </div>
                </a>

                <a
                  id="anasayfa-social-link-instagram"
                  target="_blank"
                  href="https://www.instagram.com/turanocaklarii/"
                >
                  <div>
                    <img src="/images/yeni_tasarim/Icon_instagram.png" />
                  </div>
                </a>

                <a
                  target="_blank"
                  id="anasayfa-social-link-youtube"
                  href="https://www.youtube.com/"
                >
                  <div>
                    <img src="/images/yeni_tasarim/Icon_youtube.png" />
                  </div>
                </a>
              </div>

              <div id="tbmmde-bugun">
                <div id="tbmmde-bugun-baslik-alani">
                  <div id="tbmmde-bugun-tarih-alani">
                    <span id="tbmmde-bugun-ay-yil">
                      <b>
                        {new Date(Date.now()).toLocaleDateString("TR-tr", {
                          day: "numeric",
                          month: "long",
                        })}{" "}
                      </b>
                      <span id="tbmmde-bugun-yil">
                        <b>
                          {new Date(Date.now()).toLocaleDateString("TR-tr", {
                            year: "numeric",
                          })}
                        </b>
                      </span>
                    </span>
                    <span id="tbmmde-bugun-gun-adi">
                      Turan Ocakları Haber Sitesi
                    </span>
                  </div>

                  <div id="tbmmde-bugun-splitter"></div>

                  <div id="tbmmde-bugun-hepsi-link">
                    <a target="_blank" href="https://www.turanocaklari.com/">
                      İncele{" "}
                      <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />
                    </a>
                  </div>
                </div>

                <div
                  id="tbmmde-bugun-satirlarin-alani"
                  style={{ overflow: "auto", maxHeight: "280px" }}
                >
                  {news?.news
                    ?.slice(-8)
                    .reverse()
                    ?.map((item) => (
                      <a href={`/haber/${item?.slug}`} className="">
                        <div
                          className="pb-3 pt-2"
                          style={{ borderBottom: "1px solid #39608B" }}
                        >
                          <p
                            className=""
                            style={{ color: "#555555", fontSize: "20px" }}
                          >
                            {item?.title}
                          </p>
                          <span style={{ color: "#555555" }}>
                            {new Date(item?.createdAt).toLocaleDateString(
                              "TR-tr",
                              { day: "numeric", month: "long", year: "numeric", hour: "numeric",
                                minute: "numeric", }
                            )}
                          </span>
                        </div>
                      </a>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row g-0">
            <div className="col-md-12">
              <div id="anasayfa-link-bar">
                <a className="anasayfa-link-bar-link" href="/yakinda">
                  <div>
                    <img src="/images/yeni_tasarim/icon_parlamentolar_arasi_iliskiler.png" />
                  </div>
                  <span>
                    HALKLA
                    <br />
                    İLİŞKİLER
                  </span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-kutuphane"
                  href="/haberler"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_kutuphane.png" />
                  </div>
                  <span>
                    HABERLER VE <br />
                    BÜLTEN
                  </span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-anayasa"
                  href="tuzuk/tuzuk"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_anayasa.png" />
                  </div>
                  <span>
                    <br />
                    TÜZÜK
                  </span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-kanun"
                  href="/yakinda"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_kanun_ve_kararlar.png" />
                  </div>
                  <span>
                    KARARLAR
                    <br />
                    BİLGİ SİSTEMİ
                  </span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-edilekce"
                  href="baskan/iletisim"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_edilekce.png" />
                  </div>
                  <span>İLETİŞİM</span>
                </a>

                <a
                  className="anasayfa-link-bar-link anasayfa-link-bar-link-bilgiedinme"
                  href="/tuzuk/hakkimizda"
                >
                  <div>
                    <img src="/images/yeni_tasarim/icon_bilgi_edinme.png" />
                  </div>
                  <span>HAKKIMIZDA</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="anasayfa-haberler-area">
        <div className="container">
          <div className="row">
            <div id="anasayfa-haberler-bar">
              <div id="anasayfa-haberler-bar-baslik">HABERLER</div>

              <div id="anasayfa-haberler-bar-hepsi-link">
                <a href="/haberler">
                  HEPSİ{" "}
                  <img src="/images/yeni_tasarim/BrownRightArrowIcon.png" />{" "}
                </a>
              </div>
            </div>

            <div id="anasayfa-haberler-menu">
              {haberlLinkleri?.map((item) => (
                <a
                  id="link-haberAlaniMeclisBaskani"
                  className={`${item?.url === activeHaberTuru && "active "}`}
                  style={{ transition: "ease .25s all", cursor: "pointer" }}
                  onClick={() => AnasayfaHaberGetir(item?.url)}
                >
                  <div>{item?.text}</div>
                </a>
              ))}
            </div>

            <div id="anasayfa-haberler-active-area-holder">
              <div id="anasayfa-haberler-active-area">
                <div
                  style={{
                    display: `${
                      activeHaberTuru === "haberAlaniMeclisBaskani"
                        ? "block"
                        : "none"
                    }`,
                    opacity: `${
                      activeHaberTuru === "haberAlaniMeclisBaskani" ? 1 : 0
                    }`,
                    transition: "opacity 0.2s",
                  }}
                >
                  <div className="row">
                    {news?.news?.slice(-4)?.map((item) => (
                      <div className="col-md-3">
                        <a href={`/haber/${item?.slug}`}>
                          <div
                            className="arh ImageLazyLoader"
                            data-resim-yolu=""
                            style={{
                              backgroundImage: `url('${item?.image?.url}')`,
                            }}
                          ></div>
                          <div className="art">
                            {new Date(item?.createdAt).toLocaleDateString(
                              "TR-tr",
                              { day: "numeric", month: "long", year: "numeric" }
                            )}
                          </div>
                          <div className="arb">{item?.title}</div>
                          <div className="ard">{item?.subtitle}</div>

                          <div className="art-mobile">
                            {new Date(item?.createdAt).toLocaleDateString(
                              "TR-tr",
                              { day: "numeric", month: "long", year: "numeric" }
                            )}
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  id=""
                  style={{
                    display: `${
                      activeHaberTuru === "haberAlaniYasama" ? "block" : "none"
                    }`,
                    opacity: `${
                      activeHaberTuru === "haberAlaniYasama" ? 1 : 0
                    }`,
                    transition: "opacity 0.2s",
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <a href="/yakinda">
                        <div
                          className="arh ImageLazyLoader"
                          data-resim-yolu=""
                          style={{
                            backgroundImage: "url('/img/yakinda.png')",
                            width: "100%",
                          }}
                        ></div>
                      </a>
                    </div>
                  </div>
                </div>

                {activeHaberTuru === "haberAlaniKomisyon" && (
                  <div
                    id=""
                    style={{
                      display: `${
                        activeHaberTuru === "haberAlaniKomisyon" ? "block" : "none"
                      }`,
                      opacity: `${
                        activeHaberTuru === "haberAlaniKomisyon" ? 1 : 0
                      }`,
                      transition: "opacity 0.2s",
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <a href="/yakinda">
                          <div
                            className="arh ImageLazyLoader"
                            data-resim-yolu=""
                            style={{
                              backgroundImage: "url('img/yakinda.png')",
                              width: "100%",
                            }}
                          ></div>
                        </a>
                      </div>
                    </div>
                  </div>
                )}

                {activeHaberTuru === "haberAlaniMilletvekilleri" && (
                  <div
                    id=""
                    style={{
                      display: `${
                        activeHaberTuru === "haberAlaniMilletvekilleri"
                          ? "block"
                          : "none"
                      }`,
                      opacity: `${
                        activeHaberTuru === "haberAlaniMilletvekilleri" ? 1 : 0
                      }`,
                      transition: "opacity 0.2s",
                    }}
                  >
                    <div className="row">
                      {events?.events?.slice(-4)?.map((item) => (
                        <div className="col-md-4">
                        <a href={`/etkinlikler/vakif/${item?.slug}`}>
                          <div
                            className="arh ImageLazyLoader"
                            data-resim-yolu=""
                            style={{
                              backgroundImage: `url('${item?.images[0]?.url}')`
                            }}
                          ></div>
                          <div className="art">{new Date(item?.createdAt).toLocaleDateString("TR-tr", {day: "numeric", month: "long", year: "numeric", hour: "numeric", minute: "numeric"})}</div>
                          <div className="arb">{item?.title}</div>
                          <div className="ard line-3" dangerouslySetInnerHTML={{__html: item?.content}}>
                            
                          </div>
                          <div className="art-mobile">{new Date(item?.createdAt).toLocaleDateString("TR-tr", {day: "numeric", month: "long", year: "numeric", hour:"numeric", minute: "numeric"})}</div>
                        </a>
                      </div>
                      ))
                        }
                      
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="anasayfa-duyurular-area">
        <div className="container">
          <div className="row">
            <div className="col-md-3" style={{ padding: "0px" }}>
              <div id="anasayfa-etkinlikler">
                <div className="duyuru-baslik-holder">
                  <div className="duyuru-baslik">ETKİNLİKLER</div>
                </div>

                <div className="duyurular-content">
                  <a href="/etkinlikler/vakif">
                    <img src="/img/etkinliklerimiz.png" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-3" style={{ padding: "0px" }}>
              <div id="anasayfa-fotograflarla-tbmm">
                <div className="duyuru-baslik-holder">
                  <div className="duyuru-baslik">FOTOĞRAFLARLA BİZ</div>
                </div>
                <div className="duyurular-content">
                  <a href="/yakinda">
                    <img src="img/fotograflar-biz.png" />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-md-3" style={{ padding: "0px" }}>
              <div id="anasayfa-sanal-tur-holder">
                <div className="duyuru-baslik-holder">
                  <div className="duyuru-baslik">BASINDA BİZ</div>
                </div>
                <div className="duyurular-content">
                  <a href="/yakinda">
                    <img src="img/basinda-biz.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
