import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";
import { Carousel } from "antd";
import { useGetEventDetailsQuery } from "../../redux/api/eventApi";
import { useParams } from "react-router-dom";
import Loader from "../../layouts/Loader";

const EventDetails = () => {
  const { slug } = useParams();
  const { data: event, isLoading } = useGetEventDetailsQuery(slug);

  if (isLoading) {
    return <Loader />;
  }
  
  const contentStyle = {
    margin: 0,
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  return (
    <PageLayouts>
      <MetaData title={`${event?.event?.title}`} />
      <div id="content-dinamik-title">
        <div id="content-title-type">
          <a style={{ color: "#c4b084" }}>{event?.event?.title}</a>
        </div>
        <div id="content-title-hepsi"></div>
      </div>
      <div id="detay-content" style={{ width: "100%" }}>
        <div className="blog-media">
          <Carousel arrows infinite={false}>
            {event?.event?.images?.map((img) => (
              <img
                className="HaberResimYukleme radius5 img-fluid"
                data-resim-yolu=""
                loading="lazy"
                style={{ width: "100%" }}
                src={img?.url}
              />
            ))}
          </Carousel>
        </div>
        <div className="blog-content">
          <div id="text-description">
            <div className="bright-text">
              <div id="haber-detay-baslik">
                <div
                  id="haber-detay-saat"
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div
                    id="haber-detay-saat-dakika"
                    style={{ display: "inline-block" }}
                  >
                    {new Date(event?.event?.createdAt).toLocaleDateString(
                      "TR-tr",
                      {
                        month: "long",
                        day: "numeric",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }
                    )}
                  </div>

                  <div className="mobile-social-media">
                    <a
                      className="a-facebook"
                      target="_blank"
                      href="https://www.facebook.com/tuglar/"
                    >
                      <img src="/images/yeni_tasarim/renk/Icon_facebook.png" />
                    </a>
                    <a
                      className="a-facebook"
                      target="_blank"
                      href="https://www.instagram.com/turanocaklarii/"
                    >
                      <img src="/images/yeni_tasarim/renk/Icon_instagram.png" />
                    </a>
                    <a
                      className="a-facebook"
                      target="_blank"
                      href="https://x.com/turanocaklarii"
                    >
                      <img src="/images/yeni_tasarim/renk/Icon_twitter.png" />
                    </a>
                  </div>
                </div>
                {event?.event?.title}
              </div>
              <div
                id="haber-detay-aciklama"
                dangerouslySetInnerHTML={{ __html: event?.event?.content }}
              />
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default EventDetails;
