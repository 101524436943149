import React from "react";
import PageLayouts from "../../layouts/PageLayouts";
import MetaData from "../../layouts/MetaData";
import ModalComp from "../../layouts/Modal";

const YonetimKurulu = () => {
  const kuruluUye = [
    {
      fullname: "Abdulkadir KAPLAN",
      role: "Genel Başkan",
      img: "/img/uyeler/abdulkadir-parlak-yonetim.jpeg",
    },
    {
      fullname: "Alptekin YÜREK",
      role: "Genel Sekreter",
      img: "/img/uyeler/alptekin-yurek.png",
    },
    {
      fullname: "Berzan ÖNER",
      role: "Genel Merkez Sözcüsü",
      img: "/img/uyeler/berzan-oner-2.png",
    },
    {
      fullname: "Erol ADIGÜZEL",
      role: "Genel Başkan Yardımcısı",
      img: "/img/uyeler/erol-adiguzel.jpeg",
    },
    {
      fullname: "-",
      role: "Genel Başkan Yardımcısı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "-",
      role: "Teşkilat Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "-",
      role: "Hukuki İlişkiler Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "-",
      role: "Sosyal Politikalar Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
    {
      fullname: "Ömer AYDIN",
      role: "Denetim Kurulu Başkanı",
      img: "/img/uyeler/omer-aydin.jpg",
    },
    {
      fullname: "Yusuf GENCELİ",
      role: "Tanıtım ve Medya Başkanı",
      img: "/img/uyeler/yusuf-gencel.jpeg",
    },
    {
      fullname: "Hüseyin KAPLAN",
      role: "Mali ve İdari İşler Başkanı",
      img: "/img/uyeler/huseyin-kaplan.jpeg",
    },
    {
      fullname: "Abdussamed KAPLAN",
      role: "AR-GE Başkanı",
      img: "/img/bayrak-logo.jpeg",
    },
  ];
  
  return (
    <PageLayouts>
      <MetaData title={"Yönetim Kurulu"} />
      <div id="content-dinamik-title">
        <div id="content-title-type" style={{ width: "60%" }}>
          Yönetim Kurulu
        </div>
      </div>

      <div className="blog-content tbmm-div-list alt-sayfa-content">
        <div className="">
          <div className="baskanlik-anasayfa-alt-haber ">
            <div className="row gap-5 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row">
                  {kuruluUye?.map((uye) => (
                    <div className="col-sm-12 col-md-6 col-lg-3 kurul-card-wrapper mb-4">
                      <div className="rounded-2 shadow">
                        <img src={uye?.img} alt={`${uye?.fullname}-img`} className="rounded-1 kurul-card" />
                        <div className="kurul-card-shadow" style={{height: "1px", backgroundColor: "#d3bebe", width: "100%"}} />
                        <div className="d-flex flex-column justify-content-center align-items-center border-t" >
                          <h1 className="mt-4" style={{color: "#1F1F1F", fontSize: "13px"}}>{uye?.fullname}</h1>
                          <p className="" style={{color: "#BBB"}}>{uye?.role}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageLayouts>
  );
};

export default YonetimKurulu;
