import React, { useEffect } from "react";
import {
  useDeleteNewsMutation,
  useGetNewsQuery,
} from "../../../redux/api/newsApi";
import { toast } from "react-hot-toast";
import {
  useDeleteEventMutation,
  useGetEventsQuery,
} from "../../../redux/api/eventApi";
const GetEvents = () => {
  const { data: events, isLoading, error } = useGetEventsQuery({category: ""});
  const [deleteEvents, { isLoading: newsLoading, error: newsError, isSuccess }] =
    useDeleteEventMutation();

  useEffect(() => {
    if (isSuccess) {
      toast.success("Haber Silindi.");
    }
    if (newsError) {
      toast.error(newsError?.data?.message);
    }
  }, [newsError, isSuccess]);
  return (
    <table className="table table-striped table-bordered">
      <thead className="table-primary">
        <tr>
          <th>Başlık</th>
          <th>Tarih</th>
          <th>İşlemler</th>
        </tr>
      </thead>
      <tbody>
        {events?.events?.length > 0 ? (
          events?.events?.map((item, index) => (
            <tr key={item._id}>
              <td>{item.title}</td>
              <td>
                {new Date(item.createdAt).toLocaleDateString("tr-TR", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </td>
              <td>
                <a
                  href={`/admin/etkinlik-duzenle/${item?.slug}`}
                  className="btn btn-primary btn-sm me-2"
                >
                  Düzenle
                </a>
                <button
                  className="btn btn-danger btn-sm"
                  disabled={newsLoading}
                  onClick={() => deleteEvents(item?._id)}
                >
                  Sil
                </button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5" className="text-center">
              Hiç haber bulunamadı.
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default GetEvents;
