import React from "react";

const Footer = () => {
  return (
    <div id="footer-container">
      <div className="container">
        <div className="row">
          <div className="col-md-12" style={{padding: "25px"}}>
            <div id="footer-linkler-alani">

              <div id="fl-baskanlik">
                <span>
                  <a
                    href="javascript:void(0)"
                    onclick="MobilFooterMenuAcKapat('tbmmBaskanligi')"
                  >
                    ANASAYFA
                    <img src="/img/footer_asaga_ok.png" />
                  </a>
                </span>
                <ul id="tbmmBaskanligi">
                  <li>
                    <a href="/yakinda">
                      <i className="icon-caret-right"></i>HALKLA İLİŞKİLER
                    </a>
                  </li>
                  <li>
                    <a href="/haberler">
                      <i className="icon-caret-right"></i>HABERLER VE BÜLTENLER
                    </a>
                  </li>
                  <li>
                    <a href="/tuzuk/tuzuk">
                      <i className="icon-caret-right"></i>TÜZÜK
                    </a>
                  </li>
                  <li>
                    <a href="/yakinda">
                      <i className="icon-caret-right"></i>KARARLAR BİLGİ SİSTEMİ
                    </a>
                  </li>
                  <li>
                    <a href="/baskan/iletisim">
                      <i className="icon-caret-right"></i>E POSTA
                    </a>
                  </li>
                </ul>
              </div>

              <div id="fl-idari-teskilat">
                <span>
                  <a
                    href="javascript:void(0)"
                    onclick="MobilFooterMenuAcKapat('idariTeskilat')"
                  >
                    KURUMSAL
                    <img src="/img/footer_asaga_ok.png" />
                  </a>
                </span>
                <ul id="idariTeskilat">
                  <li>
                    <a href="/baskan/ozgecmis">
                      <i className="icon-caret-right"></i>GENEL BAŞKAN
                    </a>
                  </li>
                  <li>
                    <a href="/baskan/yonetim-kurulu">
                      <i className="icon-caret-right"></i>YÖNETİM KURULU
                    </a>
                  </li>
                  <li>
                    <a href="/baskan/denetim-kurulu">
                      <i className="icon-caret-right"></i>DENETİM KURULU
                    </a>
                  </li>
                  <li>
                    <a href="/haberler">
                      <i className="icon-caret-right"></i>HABERLER
                    </a>
                  </li>
                  <li>
                    <a href="/baskan/mesajlar">
                      <i className="icon-caret-right"></i>MESAJLAR
                    </a>
                  </li>
                  <li>
                    <a href="/baskan/iletisim">
                      <i className="icon-caret-right"></i>İLETİŞİM
                    </a>
                  </li>
                </ul>
              </div>

              <div id="fl-tbmm-ziyaret">
                <span>
                  <a
                    href="javascript:void(0)"
                    onclick="MobilFooterMenuAcKapat('ziyaretEdin')"
                  >
                    ETKİNLİKLER
                    <img src="/img/footer_asaga_ok.png" />
                  </a>
                </span>
                <ul id="ziyaretEdin">
                  <li>
                    <a href="/etkinlikler/bagis">
                      <i className="icon-caret-right"></i>BAĞIŞ ETKİNLİKLERİ
                    </a>
                  </li>
                  <li>
                    <a href="/etkinlikler/vakif">
                      <i className="icon-caret-right"></i>VAKIF ETKİNLİKLERİ
                    </a>
                  </li>
                  <li>
                    <a href="/yakinda">
                      <i className="icon-caret-right"></i>BASIN AÇIKLAMALARI
                    </a>
                  </li>
                  <li>
                    <a href="/yakinda">
                      <i className="icon-caret-right"></i>TANIŞMA ETKİNLİKLERİ
                    </a>
                  </li>
                  <li>
                    <a href="/yakinda">
                      <i className="icon-caret-right"></i>RESMİ ZİYARETLER
                    </a>
                  </li>
                </ul>
              </div>

              <div id="fl-engelsiz-meclis">
                <span>
                  <a
                    href="javascript:void(0)"
                    onclick="MobilFooterMenuAcKapat('engelsizMeclis')"
                  >
                    GÜNDEM
                    <img src="/img/footer_asaga_ok.png" />
                  </a>
                </span>
                <ul id="engelsizMeclis">
                  <li>
                    <a href="/etkinlikler/vakif">
                      <i className="icon-caret-right"></i>VAKIF GÜNDEMİ
                    </a>
                  </li>
                  <li>
                    <a href="/yakinda">
                      <i className="icon-caret-right"></i>ULUSAL GÜNDEM
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div id="footer-end-line">
              <div id="footer-tbmm-logo-holder">
                <a href="/">
                  <img src="/img/kurumsal.png" style={{width: "300%"}} />
                </a>
              </div>

              <div id="footer-tbmm-text-holder"></div>

              <div id="footer-adres-area">
                <span id="footer-bize-ulasin-title">BİZE ULAŞIN</span>
                <address>
                  <div>
                    <span id="footer_home_icon">
                      <img src="/images/yeni_tasarim/tbmm_home_icon.png" />
                    </span>
                    Genel Merkez: Cinnah Caddesi No: 31/25 Çankaya/Ankara
                  </div>
                  <div>
                    <span>
                      <img src="/images/yeni_tasarim/tbmm_tel_icon.png" />
                    </span>
                    <a href="tel:+905362006038">0 (536) 200 60 38</a>
                  </div>
                  <div>
                    <span>
                      <img src="/images/yeni_tasarim/wp.png" />
                    </span>
                    <a href="https://wa.me/905362006038">0 (536) 200 60 38</a>
                  </div>
                  <div>
                    <span id="footer_email_icon">
                      <img src="/images/yeni_tasarim/tbmm_email_icon.png" />
                    </span>
                    <a href="mailto:info@turanocaklari.org.tr">
                      info@turanocaklari.org.tr
                    </a>
                  </div>
                </address>
              </div>

              <div id="footer-social-area">
                <a target="_blank" href="https://www.facebook.com/tuglar/">
                  <div>
                    <img
                      style={{width: "19%"}}
                      src="/images/yeni_tasarim/Icon_facebook.png"
                    />
                  </div>
                </a>

                <a target="_blank" href="https://x.com/turanocaklarii">
                  <div>
                    <img src="/images/yeni_tasarim/Icon_twitter.png" />
                  </div>
                </a>

                <a
                  target="_blank"
                  href="https://www.instagram.com/turanocaklarii/"
                >
                  <div>
                    <img src="/images/yeni_tasarim/Icon_instagram.png" />
                  </div>
                </a>

                <a
                  target="_blank"
                  id="anasayfa-social-link-youtube"
                  href="https://www.youtube.com/"
                >
                  <div>
                    <img src="/images/yeni_tasarim/Icon_youtube.png" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
