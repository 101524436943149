import React, { useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import { useCreateEventMutation } from "../../../redux/api/eventApi";
import toast from "react-hot-toast";
import { FaRegTrashAlt } from "react-icons/fa";
import { Editor } from "primereact/editor";

const CreateEvent = () => {
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [previewImage, setPreviewImage] = useState([]);

  const [create, { isSuccess, error, isLoading }] = useCreateEventMutation();

  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });
  const onChangeImage = (e) => {
    const files = Array.from(e.target.files);

    const promieses = files.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          resolve({
            url: reader.result,
            isBase64: true,
            _id: Math.floor(10000000 + Math.random() * 90000000),
          });
        };
      });
    });

    Promise.all(promieses).then((base64Images) => {
      setData({ ...data, images: base64Images });
      setPreviewImage([...base64Images]);
    });
  };
  const removeImg = (id) => {
    const filteredImages = previewImage?.images?.filter(
      (image) => image?.id !== id
    );

    setPreviewImage({ images: filteredImages });
    setData({ ...data, images: filteredImages });
  };

  const submitHandler = () => {
    create(data);
  };
  useEffect(() => {
    if (isSuccess) {
      toast.success("Etkinlik Oluşturuldu.");
      navigate("/admin/etkinlikleri-getir");
    }
    if (error) toast.error(error?.data?.message);
  }, [isSuccess, error]);

  return (
    <div className="row border rounded ">
      <h1>Etkinlik Oluştur</h1>
      <div className="col">
        <div className="mb-3">
          <label htmlFor="title" className="form-label">
            Etkinlik Başlığı
          </label>
          <input
            type="text"
            className="form-control"
            name="title"
            placeholder="Haber başlığı"
            required
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="subtitle" className="form-label">
            Etkinlik Altbaşlığı
          </label>
          <input
            type="text"
            className="form-control"
            name="subtitle"
            placeholder="Haber altbaşlığı"
            required
            onChange={onChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="content" className="form-label">
            Etkinlik İçeriği
          </label>
          <Editor
            name="content"
            onChange={onChange}
            required
            onTextChange={(e) => setData({ ...data, content: e.htmlValue })}
            style={{ height: "320px" }}
          />
        </div>
        <div className="mb-1">
          <label htmlFor="images" className="form-label">
            Etkinlik Resmi
          </label>
          <input
            type="file"
            className="form-control"
            name="images"
            multiple
            onChange={onChangeImage}
          />
        </div>
        {previewImage !== "" && (
          <div className="d-flex gap-2 mb-3">
            {previewImage?.map((img, index) => (
              <div
                className="border rounded-2"
                style={{ position: "relative" }}
                onClick={() => removeImg(img._id)}
              >
                <img
                  src={img.url}
                  alt=""
                  className="rounded-2"
                  style={{ width: "120px", height: "120px", cursor: "pointer" }}
                />
                <div className="preview-img rounded-2 ">
                  <FaRegTrashAlt color="white" size={24} />
                </div>
                <div
                  className={`${
                    index === 0 ? "active-preview-img rounded-bottom" : "d-none"
                  } `}
                >
                  KAPAK RESMİ
                </div>
              </div>
            ))}
          </div>
        )}
        <div className="mb-3">
          <label htmlFor="category" className="form-label">
            Etkinlik Kategorisi
          </label>
          <select
            className="form-select"
            name="category"
            onChange={onChange}
            required
            defaultValue=""
          >
            <option value="" disabled>
              Kategori Seçin
            </option>
            <option value="bagis">Bağış</option>
            <option value="vakif">Vakıf</option>
          </select>
        </div>
        {data?.category === "bagis" && (
          <div className="mb-3">
            <label htmlFor="url" className="form-label">
              Etkinlik URL
            </label>
            <input
              type="text"
              className="form-control"
              name="url"
              required
              placeholder="Etkinlik URL"
              onChange={onChange}
            />
          </div>
        )}

        <div className="mb-3 d-flex justify-content-end">
          <button
            className="btn btn-primary"
            disabled={isLoading}
            onClick={() => submitHandler()}
          >
            {isLoading ? "Oluşturuluyor..." : "Oluştur"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
