import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const eventApi = createApi({
  reducerPath: "eventApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://to-fullstack.onrender.com/api", credentials: "include" }),
  tagTypes: ["Event"],
  endpoints: (builder) => ({
    getEvents: builder.query({
      query: ({category}) => `/events?category=${category}`,
      providesTags: ["Event"],
    }),
    getEventDetails: builder.query({
      query: (slug) => `/event/${slug}`,
      providesTags: ["Event"],
    }),
    createEvent: builder.mutation({
      query(body) {
        return {
          url: "/create-event",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Event"],
    }),
    deleteEvent: builder.mutation({
      query(id) {
        return {
          url: `/event/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Event"],
    }),
    updateEvent: builder.mutation({
      query(body) {
        return {
          url: `/event/${body.id}`,
          method: "PUT",
          body
        };
      },
      invalidatesTags: ["Event"],
    }),
  }),
});

export const {
  useCreateEventMutation,
  useGetEventsQuery,
  useDeleteEventMutation,
  useGetEventDetailsQuery,
  useUpdateEventMutation
} = eventApi;
