import React, { useEffect, useState } from "react";
import { FaCalendarPlus, FaSquarePlus, FaUsers } from "react-icons/fa6";
import { IoNewspaperOutline } from "react-icons/io5";
import { MdEvent } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../layouts/Loader";
import toast from "react-hot-toast";
import { useLazyLogoutQuery } from "../redux/api/authApi";

const AdminLayouts = ({ children }) => {
  const [activeBar, setActiveBar] = useState("");
  const navigate = useNavigate();
  const sidebarItems = [
    {
      url: "/admin/haberleri-getir",
      text: "Haberler",
      icon: <IoNewspaperOutline size={38} />,
    },
    {
      url: "/admin/haber-olustur",
      text: "Haber Oluştur",
      icon: <FaSquarePlus size={38} />,
    },
    {
      url: "/admin/kullanicilari-getir",
      text: "Kullanıcılar",
      icon: <FaUsers size={38} />,
    },
    {
      url: "/admin/etkinlikleri-getir",
      text: "Etkinlikler",
      icon: <MdEvent size={38} />,
    },
    {
      url: "/admin/etkinlik-olustur",
      text: "Etkinlik Oluştur",
      icon: <FaCalendarPlus size={38} />,
    },
  ];

  const [logout, { isSuccess }] = useLazyLogoutQuery();
  const logoutHandler = () => {
    logout();
  };

  useEffect(() => {
    if (window) {
      setActiveBar(window.location.pathname);
    }
  }, [activeBar]);

  const { user, isAuthentication, loading } = useSelector(
    (state) => state.auth
  );
  if (loading) return <Loader />;
  if (isAuthentication) navigate("/giris");
  if (user?.data?.role !== "admin") {
    toast.error("Yetki dışı işlem");
    navigate("/giris");
  }

  return (
    <div className="container mt-5">
      <div className="row mb-5">
        <div
          className="d-flex flex-column justify-content-center align-items-center "
          style={{}}
        >
          <div
            className="border rounded-1"
            style={{
              maxWidth: "400px",
              width: "100%",
              maxHeight: "200px",
              overflow: "auto",
            }}
          >
            {sidebarItems?.map((item) => (
              <div
                className="d-flex gap-3 align-items-center p-2 "
                style={{
                  width: "100%",
                  backgroundColor: `${activeBar == item?.url && "#39608B"}`,
                  color: `${activeBar == item?.url && "white"}`,
                }}
              >
                {item.icon}
                <a
                  href={item.url}
                  className=""
                  style={{ color: `${activeBar == item?.url && "white"}` }}
                >
                  <span>{item.text}</span>
                </a>
              </div>
            ))}
            <div
              className="d-flex gap-3 align-items-center p-2 "
              onClick={logoutHandler}
              style={{
                width: "100%",
              }}
            >
              <a className="">
                <span>Çıkış</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

export default AdminLayouts;
